<template>
  <div class="px-8 px-md-10 py-8">
    <div class="d-flex justify-end">
      <v-btn color="primary" dark depressed @click="update()" v-text="$t('common.save')" />
    </div>
    <v-row>
      <v-col md="8" sm="12">
        <div class="mb-12">
          <div class="mb-3 d-flex">
            <h2 class="mr-3" v-text="`${$t('project.sponsorizationProject.sponsorName')} ${$t('common.optional')}`" />
            <global-tooltip
              :text="$t('project.sponsorizationProject.sponsorNameTooltip')"
            />
          </div>
          <h3 class="mb-4" v-text="'Nombre proyecto'" />
          <div class="d-flex align-baseline">
            <div>
              <span class="mr-3" v-text="$t('project.sponsorizationProject.by')" />
            </div>
            <v-text-field v-model="form.mainSponsorName" class="align-center" outlined dense :label="$t('project.sponsorizationProject.sponsorName')"
                          @blur="$v.form.mainSponsorName.$touch()"
            />
          </div>
        </div>
        <sponsorization-project-banner-data
          v-if="sections.projectHome"
          :title-section="`${$t('project.sponsorizationProject.projectHome')} ${$t('common.optional')}`"
          :text-tooltip-title-section="`${$t('project.sponsorizationProject.projectHomeTooltip')} ${$t('common.tooltipImageSizes', {
            proportion: '8:1',
            width: '640px',
            height: '80px'
          })}`"
          :width="640" :height="80" suffix="_projectHome"
          :value-image-link="form.projectHome.banner" :value-sponsored-link="form.projectHome.link"
          @inputImage="$v.form.projectHome.banner.$touch(); form.projectHome.banner = $event"
          @inputSponsoredLink="$v.form.projectHome.link.$touch(); form.projectHome.link = $event"
        />
        <sponsorization-project-banner-data
          v-if="sections.matchesTab"
          :title-section="`${$t('project.sponsorizationProject.matchesTab')} ${$t('common.optional')}`"
          suffix="_matchesTab"
          :value-image-link="form.matchesTab.banner" :value-sponsored-link="form.matchesTab.link"
          @inputImage="$v.form.matchesTab.banner.$touch(); form.matchesTab.banner = $event"
          @inputSponsoredLink="$v.form.matchesTab.link.$touch(); form.matchesTab.link = $event"
        />
        <sponsorization-project-banner-data
          v-if="sections.matchDetail"
          :title-section="`${$t('project.sponsorizationProject.matchDetail')} ${$t('common.optional')}`"
          suffix="_matchDetail"
          :value-image-link="form.matchDetail.banner" :value-sponsored-link="form.matchDetail.link"
          @inputImage="$v.form.matchDetail.banner.$touch(); form.matchDetail.banner = $event"
          @inputSponsoredLink="$v.form.matchDetail.link.$touch(); form.matchDetail.link = $event"
        />
        <sponsorization-project-banner-data
          v-if="sections.standingsTab"
          :title-section="`${$t('project.sponsorizationProject.standingsTab')} ${$t('common.optional')}`"
          suffix="_standingsTab"
          :value-image-link="form.standingsTab.banner" :value-sponsored-link="form.standingsTab.link"
          @inputImage="$v.form.standingsTab.banner.$touch(); form.standingsTab.banner = $event"
          @inputSponsoredLink="$v.form.standingsTab.link.$touch(); form.standingsTab.link = $event"
        />
        <sponsorization-project-banner-data
          v-if="sections.knockoutTab"
          :title-section="titleKnockoutTab"
          suffix="_knockoutTab"
          :value-image-link="form.knockoutTab.banner" :value-sponsored-link="form.knockoutTab.link"
          @inputImage="$v.form.knockoutTab.banner.$touch(); form.knockoutTab.banner = $event"
          @inputSponsoredLink="$v.form.knockoutTab.link.$touch(); form.knockoutTab.link = $event"
        />
        <sponsorization-project-banner-data
          v-if="sections.statsTab"
          :title-section="`${$t('project.sponsorizationProject.statsTab')} ${$t('common.optional')}`"
          suffix="_statsTab"
          :value-image-link="form.statsTab.banner" :value-sponsored-link="form.statsTab.link"
          @inputImage="$v.form.statsTab.banner.$touch(); form.statsTab.banner = $event"
          @inputSponsoredLink="$v.form.statsTab.link.$touch(); form.statsTab.link = $event"
        />
        <sponsorization-project-banner-data
          v-if="sections.teamsTab"
          :title-section="`${$t('project.sponsorizationProject.teamsTab')} ${$t('common.optional')}`"
          suffix="_teamsTab"
          :value-image-link="form.teamsTab.banner" :value-sponsored-link="form.teamsTab.link"
          @inputImage="$v.form.teamsTab.banner.$touch(); form.teamsTab.banner = $event"
          @inputSponsoredLink="$v.form.teamsTab.link.$touch(); form.teamsTab.link = $event"
        />
      </v-col>
    </v-row>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import saveDialogUtils from '@/utils/mixins/saveDialog'

export default {
  name: 'SponsorizationProject',
  components: {
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
    SponsorizationProjectBannerData: () => import('@/modules/project/SponsorizationProjectBannerData'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
  },
  mixins: [saveDialogUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters('competition', ['hasRrobinSubgroups']),
    titleKnockoutTab() {
      return this.hasRrobinSubgroups ? `${this.$t('project.sponsorizationProject.knockoutTabNBALeague')} ${this.$t('common.optional')}` : `${this.$t('project.sponsorizationProject.knockoutTab')} ${this.$t('common.optional')}`
    },
    sections: ({ project }) => ({
      projectHome: true,
      matchesTab: true,
      matchDetail: true,
      standingsTab: true,
      knockoutTab: project.subtype === 'rrobin+elimination',
      statsTab: project.features.hasMatchEvents,
      teamsTab: true,
    }),
  },
  validations() {
    return {
      form: {
        ...Object.fromEntries(Object.keys(this.form)
          .map(key => ([key, key === 'mainSponsorName' ? {} : { banner: {}, link: {} }]))),
      },
    }
  },
  created() {
    this.form = {
      mainSponsorName: this.project.ads?.mainSponsorName ?? null,
      projectHome: {
        banner: this.project.ads?.projectHome?.banner ?? null,
        link: this.project.ads?.projectHome?.link ?? null,
      },
      matchesTab: {
        banner: this.project.ads?.matchesTab?.banner ?? null,
        link: this.project.ads?.matchesTab?.link ?? null,
      },
      matchDetail: {
        banner: this.project.ads?.matchDetail?.banner ?? null,
        link: this.project.ads?.matchDetail?.link ?? null,
      },
      standingsTab: {
        banner: this.project.ads?.standingsTab?.banner ?? null,
        link: this.project.ads?.standingsTab?.link ?? null,
      },
      statsTab: {
        banner: this.project.ads?.statsTab?.banner ?? null,
        link: this.project.ads?.statsTab?.link ?? null,
      },
      knockoutTab: {
        banner: this.project.ads?.knockoutTab?.banner ?? null,
        link: this.project.ads?.knockoutTab?.link ?? null,
      },
      teamsTab: {
        banner: this.project.ads?.teamsTab?.banner ?? null,
        link: this.project.ads?.teamsTab?.link ?? null,
      },
    }
  },
  methods: {
    async update() {
      const { organizationId, projectId } = this
      await this.runAsync(() => this.$store.dispatch('project/updateAds', { organizationId, projectId, data: this.form }))
      this.userAgreedToLeave = true
    },
  },
}
</script>
